<template>
  <div @click.self="shopShow = false" style="overflow-x: hidden" v-loading.fullscreen.lock="fullscreenLoading">
    <Navigation/>
    <media_screen path_arr="path_array2" :category_id="4" @screen_confirm="pageChange(1)"
                  @reset_screen="pageChange(1)"/>
    <table class="table_OfficialAccounts">
      <thead>
      <td>媒介名称</td>
      <td>微信号</td>
      <td>头条价格</td>
      <td>普条价格</td>
      <td>行业类型</td>
      <td>所属区域</td>
      <td>粉丝量</td>
      <td>平均浏览量</td>
      <!-- <td>截稿时间</td>
      <td>出稿速度</td> -->
      <td>可发内链</td>
      <td>可发联系方式</td>
      <td>是否官方</td>
      <td>备注(仅供参考)</td>
      <td>操作</td>
      </thead>
      <tbody>
      <tr v-for="(item, index) in tableData" :key="index">
        <td>
          <td_remark :text="item.title"/>
        </td>
        <td class="over_text">
          <td_remark :text="item.account"/>
        </td>
        <td>
          <button :class="item.check == 'member_headline_price' ? 'priceActive' : 'price'"
                  @click.stop="onPitch(index, 'member_headline_price')">￥{{
              item.member_headline_price.toFixed(2)
            }}
          </button>
        </td>
        <td>
          <button :class="item.check == 'ordinary_price' ? 'priceActive' : 'price'"
                  @click.stop="onPitch(index, 'ordinary_price')">￥{{
              item.ordinary_price.toFixed(2)
            }}
          </button>
        </td>
        <td>{{ item.industry_classify_title }}</td>
        <td>{{ item.area_title }}</td>
        <td> {{ item.fans_num }}</td>
        <td>{{ item.views }}</td>
        <!-- <td>{{ item.stop_draft_time == 0 ? '无' : item.stop_draft_time + ':00' }}</td> -->
        <!-- <td>
          {{
  item.draft_speed == 0
    ? '不限'
    : item.draft_speed == 1
      ? '1小时'
      : item.draft_speed == 2
        ? '2小时'
        : item.draft_speed == 3
          ? '3小时'
          : item.draft_speed == 4
            ? '4小时'
            : item.draft_speed == 5
              ? '当天'
              : '次日'
}}
        </td> -->
        <td :style="{
		color: item.is_pub_link == 1 ? '#55C964' : '#FF0000',
	}">
          {{ item.is_pub_link == 0 ? '否' : '是' }}
        </td>
        <td :style="{
		color: item.is_pub_contact == 1 ? '#55C964' : '#FF0000',
	}">
          {{ item.is_pub_contact == 0 ? '否' : '是' }}
        </td>
        <td :style="{
		color: item.is_official == 1 ? '#55C964' : '#FF0000',
	}">
          {{ item.is_official == 1 ? '是' : '否' }}
        </td>
        <td>
          <td_remark :text="item.remark"/>
        </td>
        <td>
          <div class="collect_box flex" :style="{'justify-content':$user_info?'space-between':'center'}">
            <p @click.stop="jumpLink(item)">查看案例</p>
            <p v-if="item.is_collect == 0 && user_info" class="collect" @click.stop="collectGet(item)">收藏</p>
            <p v-if="item.is_collect == 1 && user_info" class="collect2" @click.stop="collectClose(item)">已收藏
            </p>
          </div>
        </td>

      </tr>
      </tbody>
    </table>
    <!-- 分页器 -->
    <div class="paging_box">
      <pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page"/>
    </div>
    <!-- 购物车 -->
    <div v-show="shopShow == false" class="shopping_car">
      <div class="car" @click.stop="shopShow = true">
        <p>{{ CarList.length }}</p>
      </div>
    </div>
    <div :class="shopShow ? 'shop_box' : 'shop_none_box'">
      <div class="title flex" style="justify-content: space-between; align-items: center">
        <h3 class="carTitle">已选媒介: {{ CarList.length }}</h3>
        <el-button v-show="CarList.length != 0" type="primary" @click="empty()">清空已选媒介</el-button>
        <span class="close" @click="shopShow = false"></span>
      </div>
      <ul class="listbox" v-show="CarList.length != 0">
        <li v-for="(item, index) in CarList" :key="index">
          <!-- <img :src="item.logo" alt="" /> -->
          <div class="flex" style="align-items: center;">
            <div>
              <td_remark :text="item.title"/>
            </div>
            <p class="price">{{
                item.check == 'member_headline_price' ?
                    `头条价格:￥${item.member_headline_price.toFixed(2)}` :
                    `普条价格:￥${item.ordinary_price.toFixed(2)}`
              }}</p>
          </div>
          <span class="deleteIcon" @click.stop="detale(item)"></span>
        </li>
      </ul>
      <el-empty v-show="CarList.length == 0" description="您还没有选择资源赶紧去看看心仪的资源吧"></el-empty>
      <div class="bottom_box">
        <div class="flex" style="justify-content: space-between; margin-bottom: 1rem">
          <p style="font-size: 1.4rem; color: #999">合计：</p>
          <p class="totalMoney">
            ￥<span>{{ totalMoney ? totalMoney : '0.00' }}</span>
          </p>
        </div>
        <button class="put_but" @click="putFn">立即投放</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import media_screen from '@/components/media_screen'
import {media_screen_} from '@/util/media_screen_'
import Td_remark from '@/components/td_remark';

export default {
  computed: {
    ...mapState(['media_screen_data']),
    ...mapMutations(['login_tab']),
    totalMoney: function () {
      var n = 0;
      for (var i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].check == 'member_headline_price') {
          // this.CarList.push(this.tableData[i])
          n += this.tableData[i].member_headline_price;
        } else if (this.tableData[i].check == 'ordinary_price') {
          // this.CarList.push(this.tableData[i])
          n += this.tableData[i].ordinary_price;
        }
      }
      return n.toFixed(2);
    }
  },
  components: {
    media_screen,
  },
  data() {
    return {
      user_info: {},
      /*数据表格*/
      tableData: [],
      CarList: [],
      // 购物车任务栏切换
      shopShow: false,
      Allcheck: false,
      limit: 0,
      AllcheckNum: 0, //选中总数
      ids: [],
      fullscreenLoading: false,
      total_page: 0, //分页总页数
      count: 0, //分页总页数
      page: 1,
    }
  },
  mounted() {
    this.user_info = localStorage.getItem('user_info')
  },
  methods: {
    pageChange(pageVal) {
      this.page = pageVal
      this.get_list()
    },
    onPitch(index, type) {
      this.shopShow = true
      console.log('tableDate:', this.tableData[index].check, type);
      // this.tableData[index].check = type
      const result = this.CarList.every(item => item.id !== this.tableData[index].id);
      if (result == true) {
        this.tableData[index].check = type
        this.CarList.push(this.tableData[index])
      } else {
        this.CarList.forEach((item, i) => {
          if (this.CarList[i].id == this.tableData[index].id && this.CarList[i].check != type) {
            console.log(this.CarList[i].check);
            this.tableData[index].check = type
            this.CarList[i].check = type
          } else if (this.CarList[i].id == this.tableData[index].id && this.tableData[index].check == type) {
            console.log(2);
            this.CarList.splice(i, 1)
            this.tableData[index].check = ''
          }
        })
      }
    },
    // 清空购物车
    empty() {
      this.$confirm('是否清空已选媒介?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            this.fullscreenLoading = true
            // setTimeout(() => {
            this.CarList = []
            this.shopShow = false
            // this.toggleSelection()
            this.tableData.forEach((item, index) => {
              if (item.check) {
                return (item.check = false)
              }
            })
            this.fullscreenLoading = false
            // }, 500)
            this.$message({
              type: 'success',
              message: '已清空已选媒介',
            })
          })
          .catch(() => {
            this.$message({
              type: 'warning',
              message: '已删除',
            })
          })
    },
    createBall(left, top) {
      const bar = document.createElement('ball')
      bar.style.position = 'fixed'
      bar.style.left = left + 'px'
      bar.style.top = top + 'px'
      bar.style.width = '20px'
      bar.style.height = '20px'
      bar.style.borderRadius = '50%'
      bar.style.backgroundColor = 'red'
      bar.style.transition =
          'left 1s linear, top 1s cubic-bezier(0.5, -0.5, 1, 1)'
      document.body.appendChild(bar)
      setTimeout(() => {
        const x = document.body.clientWidth * 1.5
        const y = document.body.clientHeight * 1 - 500
        bar.style.top = y + 'px'
        bar.style.left = x + 'px'
      }, 0)
      bar.ontransitionend = function () {
        this.remove()
      }
    },
    // 获取列表数据
    get_list() {
      let data = media_screen_(this.$store.state.media_screen_data.date_form)
      data.page = this.page
      data.limit = 20
      this.fullscreenLoading = true
      this.curlGet('/api/medium/list', data).then((res) => {
        if (res.data.code) {
          this.tableData = res.data.data.list
          this.count = res.data.data.count
          this.total_page = res.data.data.total_page
          this.tableData.forEach((item, index) => {
            this.$set(this.tableData[index], 'check', '')
            this.CarList.forEach((el, i) => {
              if (el.check && item.id == el.id) {
                this.$set(this.tableData[index], 'check', el.check)
              }
            })
          })
          this.shopDate(this.tableData, this.$store.state.shopObj, this.CarList)
        }
      })
      this.fullscreenLoading = false
    },
    detale(element) {
      this.CarList.forEach((el, i) => {
        if (el.id == element.id) {
          this.CarList.splice(i, 1)
        }
      })
      // this.CarList.splice(index,1)
      this.tableData.forEach((item, index) => {
        if (item.id == element.id) {
          this.tableData[index].check = ''
        }
      })
    },
    // 价格总和

    // 立即投放
    putFn() {
      let user_info = JSON.parse(localStorage.getItem('user_info')) || {}
      if (this.CarList.length == 0) {
        return this.$message({
          type: 'warning',
          message: '请选择媒介',
        })
      }
      if (Object.keys(user_info).length === 0) {
        this.$store.commit('login_tab', true)
        return this.$message({
          type: 'warning',
          message: '请登录',
        })

      }
      this.$store.commit('shopPush', this.CarList)
      this.$store.commit('SETACTIVEMENU', '/user/SetMenu/fill?id=4')
      this.$router.push({
        path: '/user/SetMenu/fill?id=4',
      })

    },
    // 收藏
    collectGet(item) {
      if (!this.user_info) {
        this.$message({
          type: 'warning',
          message: '请登录',
          offset: 90,
        })
      } else {
        this.curlGet('/api/medium/collect', {
          mid: item.id,
          style: 1
        }).then(res => {
          if (res.data.code) {
            this.$message({
              message: '收藏成功',
              type: 'success'
            })
            this.get_list()
          }
        })
      }
    },
    // 取消收藏
    collectClose(item) {
      this.$confirm('是否取消收藏?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.curlGet('/api/medium/collect', {
          mid: item.id,
          style: 1
        }).then(res => {
          if (res.data.code) {
            this.$message({
              type: 'success',
              message: '取消收藏成功!'
            });
            this.get_list()
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });

    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/Media';
@import '@/scss/price';
</style>